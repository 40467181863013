import { motion, useAnimation } from "framer-motion";
import React from "react";
import BackgroundImage from "../../assets/images/bg.png";
import LogoImage from "../../assets/images/backgroundLogo.svg";
import AppStoreBadge from "../../assets/images/appStore.svg";
import PlayStoreBadge from "../../assets/images/playStore.svg";
import { useLanguage } from "../../components/languageprovider/index";
import languages from "../../constants/language";

const Index = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const lang = currentLanguage;
  const texts = languages[lang];

  const controls = useAnimation();
  React.useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  const countries = [
    {
      id: "de",
      name: "German",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      },
    },
    {
      id: "tr",
      name: "Turkish",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
      },
    },
    {
      id: "en",
      name: "English",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
      },
    },
  ];

  return (
    <motion.div
      className="relative h-screen"
      animate={controls}
      initial={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={BackgroundImage}
          alt="Background"
        />
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>
      <div className="absolute inset-0 flex flex-col justify-center items-center text-center gap-6 px-4">
        <motion.div
          className="flex flex-wrap gap-4 items-center justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          {countries.map((country, index) => (
            <button
              key={index}
              onClick={() => {
                changeLanguage(country.id);
              }}
            >
              <img
                className="w-16 rounded-lg cursor-pointer hover:scale-110 transition ease 1s"
                src={country.flags.svg}
                alt={country.name}
              />
            </button>
          ))}
        </motion.div>
        <motion.div
          className="flex flex-col items-center gap-2"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <img
            className="md:w-1/2 w-full hover:scale-110 transition ease 1s"
            src={LogoImage}
            alt="Logo"
          />
          <span className="font-[Inter] font-bold text-4xl text-white hover:scale-110 transition ease 1s">
            {texts.appTitle}
          </span>
          <span className="font-[Inter]  text-xl text-white hover:scale-110 transition ease 1s">
            {texts.appDescription}
          </span>
        </motion.div>

        <motion.div
          className="flex flex-wrap gap-4 mt-8 items-center justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <button
            onClick={() => {
              window.open("https://www.apple.com/app-store/", "_blank");
            }}
            className="bg-white px-8 py-2 gap-2 rounded-xl flex flex-row items-center justify-center cursor-pointer scale-90 hover:scale-100 transition ease 1s"
          >
            <img className="w-16 " src={AppStoreBadge} alt="Play Store" />
            <span className="text-black font-bold">
              {texts.downloadFromAppStore}
            </span>
          </button>
          <button
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.example.app",
                "_blank"
              );
            }}
            className="bg-white px-8 py-2 gap-2 rounded-xl flex flex-row items-center justify-center cursor-pointer scale-90 hover:scale-100 transition ease 1s"
          >
            <img className="w-16" src={PlayStoreBadge} alt="Play Store" />
            <span className="text-black font-bold">
              {texts.downloadFromPlayStore}
            </span>
          </button>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Index;
