const language = {
  en: {
    appTitle: "Welcome to MyApp",
    appDescription: "Your ultimate app for all your needs.",
    addImage: "Add Image",
    downloadFromPlayStore: "Download from Play Store",
    downloadFromAppStore: "Download from App Store",
  },
  de: {
    appTitle: "Willkommen bei MyApp",
    appDescription: "Ihre ultimative App für all Ihre Bedürfnisse.",
    downloadFromPlayStore: "Vom Play Store herunterladen",
    downloadFromAppStore: "Vom App Store herunterladen",
  },
  tr: {
    appTitle: "MyApp'e Hoşgeldiniz",
    appDescription: "Tüm ihtiyaçlarınız için son uygulamanız.",
    downloadFromPlayStore: "Play Store'dan indir",
    downloadFromAppStore: "App Store'dan indir",
  },
};

export default language;
