import "./App.css";
import Main from "./pages/main";
import React from "react";

function App() {
  return (
    <div className="w-full h-screen font-[Inter] flex justify-start items-start ">
      <Main />
    </div>
  );
}

export default App;
